<script>
	import Layout from '../../layouts/main'
	import appConfig from '@/app.config'
	import PageHeader from '@/components/page-header'
	import Vue from 'vue'
	import axios from 'axios'
	import VueAxios from 'vue-axios'
	import Multiselect from 'vue-multiselect'

	Vue.use(VueAxios, axios)
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

	export default {
		middleware: "authentication",
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		data() {
			return {
				title: "Journey List",
				isReportTableBusy: false,
				excelDownloading: false,
				tableData: [],
				tableDataMonthly: [],
				var1: [],
				var2: [],
				corporateId: '',
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				sortBy: "updatedAt",
				sortDesc: true,
				employeeFilterBranch: [],
				selectedBranch: null,
				searchQuery: "",
				items: [
					{
						text: "View",
					},
					{
						text: "Journey",
						active: true,
					},
				],
				fields: [
					{
						key: 'actions',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'journeyName',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					// {
					// 	key: 'roles',
					// 	sortable: true,
					// 	tdClass: "align-center",
					// 	thStyle: {
					// 		backgroundColor: '#f6f6f6',
					// 	},
					// },
					{
						label: 'Last Modified',
						key: 'updatedAt',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						label: 'Owner',
						key: 'adminDetails[0].name',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						label: 'Created On',
						key: 'createdAt',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
				],
			};
		},
		computed: {
			rows: function() {
				return this.totalRows
			}
		},
		mounted() {
			this.corporateId = localStorage.getItem('corporateId')
			this.totalRows = this.items.length
			this.getBranchList()
		},
		methods: {
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					this.employeeFilterBranch = result.data.data
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getJourneyList()
				}).catch(error => {
					console.log(error)
				})
			},
			getJourneyList: function () {
				this.isReportTableBusy = true;
				this.axios.post('onboarding-journey/journey-list', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
					branchId: this.selectedBranch._id,
					page: this.currentPage,
					pageSize: this.pageSize,
					searchKeyword: this.searchQuery,
				}).then((result) => {
					this.isReportTableBusy = false
					this.tableData = result.data.data.listing
					this.totalRows = result.data.data.totalCounts
				}).catch((error) => {
					console.log(error)
				});
			},
			onFiltered: function (filteredItem) {
				this.totalRows = filteredItem.length
				this.currentPage = 1
			},
			copyContent: function(journeyId) {
				console.log(journeyId)
				this.axios.post('onboarding-journey/make-clone', {
					onboardJourneyId: journeyId
				}).then((result) => {
					console.log(result.data.data)
					this.$router.push('/onboarding/journey/edit/' + result.data.data.onboardJourneyDetail._id)
				}).catch((error) => {
					console.log(error)
				})
			},
			searchFilter: function(value) {
				this.searchQuery = value
				this.getJourneyList()
			},
			clearFilter() {
				this.getJourneyList()
			},
			applyFilter() {
				console.log(this.selectedBranch._id)
				this.getJourneyList()
			},
			changePage: function(value) {
				this.currentPage = value
				this.getJourneyList()
			},
			changePageSize: function(value) {
				this.pageSize = value
				this.getJourneyList()
			},
			deleteCorporate: function(id) {
				this.axios.post('onboarding-journey/delete', {
					onboardJourneyId: id,
				}).then((result) => {
					console.log(result.data)
					let statusCode = result.data.responseCode
					if (statusCode === 200) {
						this.getJourneyList()
					}
					if (statusCode === 400) {
						this.$toast.error(result.data.response, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	};
</script>
<style scoped>
	.edit-link, .btn-custom-action {
		padding: 0.47rem 0.75rem;
		font-size: 19px;
	}
	.btn, .btn:hover, .btn:active, .btn:focus {
		background: transparent;
		color: #EB455F;
		outline: 0 none !important;
		box-shadow: none;
	}
	.btn.btn-custom-action {
		border: 0 none;
	}
	.filter-card {
		margin-top: -27px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.table {
		vertical-align: middle;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card mb-4 filter-card">
					<div class="card-body">
						<div class="row inner mb-2">
							<div class="col-md-4">
								<label for="branch">Select Branch</label>
								<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								</multiselect>
							</div>
							<div class="col-md-2" style="width: auto;">
								<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
							</div>
							<div class="col-md-2" style="width: auto;">
								<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="row mt-4 mb-4">
							<div class="col-sm-12 col-md-4">
								<!-- <div class="btn-group" role="group">
									<button class="btn btn-outline-primary" type="button">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">Show &nbsp;&nbsp;<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions" @change="changePageSize"></b-form-select>&nbsp;&nbsp;&nbsp;entries</label>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">Search:<b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2" @keyup="searchFilter(filter)"></b-form-input></label>
								</div>
							</div>
						</div>
						<div class="table-responsive mb-0">
							<b-table striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								fixed-header :busy="isReportTableBusy" show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<!-- <template v-slot:cell(adminDetails)="{ item }">{{ item.adminDetails[0].name }}</template> -->
								<template v-slot:cell(actions)="{ item }">
									<router-link class="edit-link" :to="{
											name: 'journey',
											params: {
												journeyId: item._id,
												type: 'edit',
											},
										}">
										<i class="mdi mdi-pencil"></i>
									</router-link>
									<button type="button" class="btn btn-outline-primary btn-custom-action" @click="copyContent(item._id)">
										<i class="mdi mdi-content-copy"></i>
									</button>
									<router-link class="edit-link" :to="{
										name: 'journey',
											params: {
												journeyId: item._id,
												type: 'view',
											},
									}">
										<i class="mdi mdi-eye"></i>
									</router-link>
									<b-button class="btn-custom-action" variant="danger" @click="deleteCorporate(item._id)">
										<i class="mdi mdi-delete"></i>
									</b-button>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="changePage"></b-pagination>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>